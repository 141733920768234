import React from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import mq from '../../utils/mediaQuery'

const PageTitle = ({ copy }) => {
  return (
    <Root>
      <Title>{copy}</Title>
      <Divider />
    </Root>
  )
}

PageTitle.propTypes = {
  copy: PropTypes.string
}

const Root = styled.div`
  margin: 0 auto;
  max-width: 1020px;
  padding: 0 20px;
  display: flex;
  align-items: center;

  ${mq.md} {
    padding: 0 32px;
  }
`

const Title = styled.h1`
  font-size: 32px;
  color: ${props => props.theme.color.primary1};
  font-weight: 600;
  letter-spacing: 0;
  margin: 0;
`

const Divider = styled.div`
  display: none;
  flex-grow: 1;
  margin-left: 18px;
  height: 2px;
  background: ${props => props.theme.color.primary1};

  ${mq.md} {
    display: block;
  }
`
export default PageTitle
